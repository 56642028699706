import { Container } from "../Container";
import { Typography } from "../Typography";
import { theme } from "../../design-system/theme";
import { NavigationIcon } from "../NavigationIcon";
import styled, { keyframes } from "styled-components";

const MENU_HEIGTH = 155;

export const Hero = () => {
  return (
    <Container pt={170 + MENU_HEIGTH} id="inicio">
      <Hello variant="h2" fontWeight="400">
        Olá, eu sou a Camila
      </Hello>
      <AnimatedTitle variant="h1" gradient={theme.palette.gradient} mb={230}>
        UX/UI Designer
      </AnimatedTitle>
      <NavigationIcon href="#projetos" />
    </Container>
  );
};

const textShine = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const Title = styled(Typography)`
  && {
    font-family: Inter;
  }
`;

const Hello = styled(Title)`
  && {
    @media ${({ theme }) => theme.breakpoints.l} {
      font-size: 32px;
      line-height: 50px;
    }
    @media ${({ theme }) => theme.breakpoints.xs} {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const AnimatedTitle = styled(Title)`
  && {
    display: block;
    background: linear-gradient(to right, #0aa7ff, #d654d9, #0aa7ff, #d654d9);
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 200% auto;
    animation: ${textShine} 17.5s infinite linear;

    @media ${({ theme }) => theme.breakpoints.l} {
      font-size: 68px;
      line-height: 98px;
      margin-bottom: 180px;
    }
    @media ${({ theme }) => theme.breakpoints.xs} {
      font-size: 52px;
      line-height: 70px;
      margin-bottom: 100px;
    }
  }
`;
