import styled from "styled-components";

export type TypographyVariants =
  | "h1"
  | "h2"
  | "h3"
  | "body1"
  | "body2"
  | "caption";

interface ITypography {
  variant?: TypographyVariants;
  color?: string;
  children?: React.ReactNode;
  mb?: number;
  gradient?: string;
  fontWeight?: "400" | "500" | "700";
  className?: string;
}

export const Typography: React.FC<ITypography> = ({
  variant = "body2",
  color,
  children,
  mb,
  gradient,
  fontWeight,
  className,
  ...props
}) => {
  return (
    <StyledParagraph
      variant={variant}
      color={color}
      mb={mb}
      gradient={gradient}
      fontWeight={fontWeight}
      className={className}
      {...props}
    >
      {children}
    </StyledParagraph>
  );
};

const StyledParagraph = styled.p<ITypography>`
  display: inline-block;
  color: ${({ theme, color }) => color ?? theme.palette.white};
  margin-bottom: ${({ mb }) => `${mb ?? 0}px`};

  background: ${({ gradient }) => gradient ?? "none"};
  -webkit-background-clip: text;
  -webkit-text-fill-color: ${({ gradient }) =>
    gradient ? "transparent" : "none"};

  ${({ variant, theme, fontWeight }) => {
    return `
      font-size: ${theme.typography[variant ?? "body2"].fontSize};
      line-height: ${theme.typography[variant ?? "body2"].lineHeight};
      font-weight: ${
        fontWeight ??
        (["h1", "h2", "h3"].includes(variant ?? "") ? "bold" : "normal")
      }

      @media  ${theme.breakpoints.l} {
        ${variant === "h2" ? `font-size: 32px; line-height: 50px;` : null}
      }

      @media  ${theme.breakpoints.xs} {
        ${variant === "h2" ? `font-size: 24px; line-height: 32px;` : null}
      }
    `;
  }};
`;
