import { ThemeProvider } from "styled-components";
import { theme } from "./design-system/theme";
import { Menu } from "./components/Menu";
import { Layout } from "./components/Layout";
import { Hero } from "./components/Hero";
import { Projects } from "./components/Projects";
import { About } from "./components/About";

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Menu />
        <Hero />
        <Projects />
        <About />
      </Layout>
    </ThemeProvider>
  );
};
