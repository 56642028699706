import { TypographyVariants } from "../components/Typography";

interface ITheme {
  palette: Record<string, string>;
  typography: Record<
    TypographyVariants,
    { fontSize: string; lineHeight: string }
  >;
  breakpoints: Record<string, string>;
}

export const breakpoints = {
  l: 1200,
  m: 1050,
  s: 768,
  xs: 576,
};

export const theme: ITheme = {
  palette: {
    black: "#000000",
    white: "#ffffff",
    gradient: "linear-gradient(to right, #0AA7FF, #D654D9)",
    rabbit: "#003F62",
  },
  typography: {
    h1: {
      fontSize: "122px",
      lineHeight: "178px",
    },
    h2: {
      fontSize: "46px",
      lineHeight: "50px",
    },
    h3: {
      fontSize: "24px",
      lineHeight: "42px",
    },
    body1: {
      fontSize: "20px",
      lineHeight: "30px",
    },
    body2: {
      fontSize: "16px",
      lineHeight: "34px",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "16px",
    },
  },
  breakpoints: {
    l: `(max-width: ${breakpoints.l}px)`,
    m_to_l: `(min-width: ${breakpoints.m + 1}px) and (max-width: ${
      breakpoints.l - 1
    }px)`,
    m: `(max-width: ${breakpoints.m}px)`,
    s: `(max-width: ${breakpoints.s}px)`,
    xs: `(max-width: ${breakpoints.xs}px)`,
  },
};
