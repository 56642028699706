import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Typography } from "../Typography";
import styled from "styled-components";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

interface IProjectCard {
  image: string;
  title: string;
  subtitle: string;
  link: string;
  gradient: string;
  borderGradient: string;
  iconColor: string;
}

export const ProjectCard: React.FC<IProjectCard> = ({
  image,
  title,
  subtitle,
  link,
  gradient,
  borderGradient,
  iconColor,
}) => {
  return (
    <Link href={link} target="_blank">
      <ProjectPhoto image={image} id="photo">
        <ProjectHover borderGradient={borderGradient}>
          <Typography variant="h3" gradient={gradient}>
            {title}
          </Typography>
          <Typography variant="caption">{subtitle}</Typography>

          <Icon icon={faArrowRight} color={iconColor} />
        </ProjectHover>
      </ProjectPhoto>
    </Link>
  );
};

const Link = styled.a`
  flex-grow: 1;
`;

const Icon = styled(FontAwesomeIcon)`
  color: ${({ theme, color }) => color ?? theme.palette.white};
  position: absolute;
  bottom: 22px;
  right: 22px;
  font-size: 38px;
`;

const ProjectPhoto = styled.div<Pick<IProjectCard, "image">>`
  background: ${({ image }) => {
    return `url(${Object.values(image)[0]}) no-repeat center`;
  }};
  position: relative;
  height: 350px;
`;

const ProjectHover = styled.div<Pick<IProjectCard, "borderGradient">>`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.palette.black};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 22px;
  opacity: 0;
  border: 1px solid;
  transition: all 0.3s;
  border-image: ${({ borderGradient }) => `${borderGradient} 1`};

  &:hover {
    opacity: 1;
  }

  @media ${({ theme }) => theme.breakpoints.m} {
    opacity: 1;
  }
`;
