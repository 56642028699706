import styled from "styled-components";
import { Container } from "../Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMultiply } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";

const items = [
  {
    href: "#inicio",
    externalLink: false,
    title: "Início",
  },
  {
    href: "#projetos",
    externalLink: false,
    title: "Projetos",
  },
  {
    href: "#sobre-mim",
    externalLink: false,
    title: "Sobre mim",
  },
  {
    href: "https://drive.google.com/file/d/1GYagTIHRdTR0aguKi3YsDQORZ11bRe4e/view?usp=share_link",
    externalLink: true,
    title: "Currículo",
  },
];

export const Menu = () => {
  const [open, setOpen] = useState(false);
  return (
    <Nav>
      <StyledContainer pt={64} pb={32}>
        <List open={open}>
          {items.map(({ href, externalLink, title }) => (
            <li key="title">
              <a
                target={externalLink ? "_blank" : undefined}
                rel="noreferrer"
                href={href}
                onClick={() => setOpen(false)}
              >
                {title}
              </a>
            </li>
          ))}
        </List>
        <MenuIcon icon={faBars} open={open} onClick={() => setOpen(true)} />
        <CloseIcon
          icon={faMultiply}
          open={open}
          onClick={() => setOpen(false)}
        />
      </StyledContainer>
    </Nav>
  );
};

const StyledContainer = styled(Container)`
  @media ${({ theme }) => theme.breakpoints.m} {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

const Nav = styled.nav`
  position: fixed;
  background-color: ${({ theme }) => theme.palette.black};
  z-index: 1;
  width: 100%;
`;

const List = styled.ul<{ open: boolean }>`
  display: flex;
  gap: 25px;
  justify-content: flex-end;
  font-weight: bold;

  li:hover {
    background: ${({ theme }) => theme.palette.gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media ${({ theme }) => theme.breakpoints.m} {
    display: ${({ open }) => (open ? "flex" : "none")};
    justify-content: flex-start;
    flex-direction: column;
    gap: 16px;

    li: {
      align-self: flex-start;
    }
  }
`;

const MenuIcon = styled(FontAwesomeIcon)<{ open: boolean }>`
  display: none;
  color: ${({ theme, color }) => color ?? theme.palette.white};
  position: absolute;
  right: 64px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 25px;

  &:hover {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.breakpoints.m} {
    display: ${({ open }) => (open ? "none" : "block")};
  }
`;

const CloseIcon = styled(FontAwesomeIcon)<{ open: boolean }>`
  display: none;
  color: ${({ theme, color }) => color ?? theme.palette.white};
  position: absolute;
  top: 40px;
  right: 64px;
  font-size: 25px;

  &:hover {
    cursor: pointer;
  }

  @media ${({ theme }) => theme.breakpoints.m} {
    display: ${({ open }) => (open ? "block" : "none")};
  }
`;
