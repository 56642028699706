import styled from "styled-components";
import { theme } from "../../design-system/theme";
import { Container } from "../Container";
import { Typography } from "../Typography";
import fotoCamila from "../../assets/fotoCamila.png";
import linkedin from "../../assets/linkedin.png";
import behance from "../../assets/behance.png";
import { Title } from "../Projects";

export const About = () => {
  return (
    <Container pb={155} id="sobre-mim">
      <Title variant="h2" gradient={theme.palette.gradient} mb={155}>
        Sobre mim
      </Title>
      <Section>
        <ImageColumn>
          <ImageWrapper>
            <Image src={fotoCamila} />
          </ImageWrapper>
        </ImageColumn>
        <TextColumn>
          <Typography mb={30}>
            Olá! Me chamo Camila, sou UX/UI Designer formada na EBAC (Escola
            Britânica de Artes Criativas e Tecnologia).{" "}
          </Typography>
          <Typography>
            Tenho como objetivo pensar sempre no usuário e suas necessidades,
            trazer soluções para resolver problemas, facilitar a rotina e
            melhorar sua experiência.
          </Typography>
        </TextColumn>
      </Section>
      <SocialMediaBox>
        <a
          href="https://www.behance.net/camilaneiva1"
          target="_blank"
          rel="noreferrer"
        >
          <img src={behance} alt="Behance link" />
        </a>
        <a
          href="https://www.linkedin.com/in/camila-neiva-martins/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={linkedin} alt="Linkedin link" />
        </a>
      </SocialMediaBox>
    </Container>
  );
};

const Section = styled.section`
  margin: 0 auto;
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 120px;
  @media ${({ theme }) => theme.breakpoints.xs} {
    flex-direction: column;
  }
`;

const ImageColumn = styled.div`
  align-self: center;
  @media ${({ theme }) => theme.breakpoints.xs} {
    margin-top: 30px;
    order: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: -7px;
    bottom: -7px;
    background: ${({ theme }) => theme.palette.gradient};
    z-index: -1;
  }
`;

const Image = styled.img`
  max-width: 161.75px;
  opacity: 1;
  z-index: 1;
`;

const TextColumn = styled.div`
  max-width: 335px;
  @media ${({ theme }) => theme.breakpoints.s} {
    max-width: unset;
  }
`;

const SocialMediaBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 25px;
`;
