import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

interface INavigationIcon {
  href: string;
}

export const NavigationIcon: React.FC<INavigationIcon> = ({ href }) => {
  return (
    <a href={href}>
      <Icon icon={faArrowDown} />
    </a>
  );
};

const Icon = styled(FontAwesomeIcon)`
  color: #003f62;
  font-size: 60px;
  margin: 0 auto;
  display: block;
  @media ${({ theme }) => theme.breakpoints.m} {
    font-size: 40px;
  }
  @media ${({ theme }) => theme.breakpoints.s} {
    display: none;
  }
`;
