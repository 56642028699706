import styled from "styled-components";

interface IContainer {
  children?: React.ReactNode;
  pt?: number;
  pb?: number;
  id?: string;
  className?: string;
}

export const Container: React.FC<IContainer> = ({
  children,
  pt,
  pb,
  id,
  className,
  ...props
}) => {
  return (
    <StyledContainer pt={pt} pb={pb} id={id} className={className}>
      {children}
    </StyledContainer>
  );
};

const StyledContainer = styled.div<IContainer>`
  max-width: 1270px;
  margin: 0 auto;
  padding: 0 64px;
  padding-top: ${({ pt }) => `${pt ?? 0}px`};
  padding-bottom: ${({ pb }) => `${pb ?? 0}px`};
  position: relative;
  z-index: 0;

  @media ${({ theme }) => theme.breakpoints.l} {
    padding-top: ${({ pt }) => `${pt ? pt - 80 : 0}px`};
    padding-bottom: ${({ pb }) => `${pb ? pb - 80 : 0}px`};
  }
`;
