import { Container } from "../Container";
import { ProjectCard } from "../ProjectCard";
import { Typography } from "../Typography";
import gymlife from "../../assets/gymlife.png";
import gooday from "../../assets/gooday.png";
import bikcraft from "../../assets/bikcraft.png";
import styled from "styled-components";
import { theme } from "../../design-system/theme";
import { NavigationIcon } from "../NavigationIcon";

const projects = [
  {
    image: { gymlife },
    title: "GymLife",
    subtitle: "Mais qualidade e uma melhor experiência de treino.",
    link: "https://www.behance.net/gallery/160412247/Estudo-de-Caso-UXUI-Design-GymLife",
    gradient: "linear-gradient(to right, #C0CA73, #26F222)",
    borderGradient: "linear-gradient(to bottom right, #C0CA73, #26F222)",
    iconColor: "rgba(192, 202, 115, 0.4)",
  },
  {
    image: { gooday },
    title: "Gooday",
    subtitle: "Plataforma para quem busca uma vida saudável",
    link: "https://www.behance.net/gallery/161805035/Gooday",
    gradient: "linear-gradient(to right, #10A05A, #F2F3F1)",
    borderGradient: "linear-gradient(to bottom right, #10A05A, #F2F3F1)",
    iconColor: "rgba(16, 160, 90, 0.4)",
  },
  {
    image: { bikcraft },
    title: "Bikcraft",
    subtitle: "Bicicletas personalizadas",
    link: "https://www.behance.net/gallery/160895803/Bikcraft",
    gradient: "linear-gradient(to right, #FEC63E, #8E8B84)",
    borderGradient: "linear-gradient(to bottom right, #FEC63E, #8E8B84)",
    iconColor: "rgba(254, 198, 62, 0.4)",
  },
];

export const Projects = () => {
  return (
    <Container pt={170} pb={130} id="projetos">
      <Title gradient={theme.palette.gradient} variant="h2" mb={155}>
        Projetos
      </Title>
      <Box>
        {projects.map(
          ({
            image,
            title,
            subtitle,
            link,
            gradient,
            borderGradient,
            iconColor,
          }) => (
            <ProjectCard
              key={title}
              image={image as any}
              title={title}
              subtitle={subtitle}
              link={link}
              gradient={gradient}
              borderGradient={borderGradient}
              iconColor={iconColor}
            />
          )
        )}
      </Box>
      <NavigationIcon href="#sobre-mim" />
    </Container>
  );
};

const Box = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 155px;
  @media ${({ theme }) => theme.breakpoints.m} {
    flex-direction: column;
  }
`;

export const Title = styled(Typography)`
  && {
    @media ${({ theme }) => theme.breakpoints.l} {
      margin-bottom: 180px;
    }
    @media ${({ theme }) => theme.breakpoints.xs} {
      margin-bottom: 100px;
    }
  }
`;
